'use client';

import React from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import cx from 'classnames';
import { tooltipStyles } from 'components/Chart/helpers';
import useScreenSizeDetect from 'hooks/useScreenSizeDetect';
import styles from './Tooltip.module.scss';
interface TooltipCustomProps {
  Component: React.ComponentType<TooltipInPortalProps>;
  left: number;
  top: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  adaptive?: boolean;
}
const TooltipContainer: React.FC<TooltipCustomProps> = ({
  Component,
  children,
  left,
  top,
  className,
  style = {},
  adaptive = true
}) => {
  const {
    isMobile
  } = useScreenSizeDetect();
  return <Component className={cx(styles.root, className)} left={left} top={top} style={{
    ...(adaptive && isMobile && {
      left: '50%',
      transform: 'translateX(-50%)'
    }),
    zIndex: 2500,
    ...tooltipStyles,
    ...style
  }} data-sentry-element="Component" data-sentry-component="TooltipContainer" data-sentry-source-file="Tooltip.tsx">
      {children}
    </Component>;
};
export default TooltipContainer;