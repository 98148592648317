import { numericFormatter } from 'react-number-format';

const formatThousands = (value?: string | number): string => {
  if (typeof value === 'number' || typeof value === 'string') {
    return numericFormatter(value.toString(), {
      thousandSeparator: ' ',
    });
  }

  return '';
};

export default formatThousands;
