import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/date';

type TFilter = {
  name: string;
  value: Date | (number | string)[] | string | number | boolean;
};

type NavigateOptions = {
  scroll?: boolean;
};

const normalizeFilter = (filter: TFilter) => {
  if (filter.value instanceof Date) {
    return {
      ...filter,
      value: dayjs(filter.value).format(DATE_FORMAT),
    };
  }

  if (Array.isArray(filter.value)) {
    return {
      ...filter,
      value: filter.value.join(','),
    };
  }

  if (typeof filter.value === 'boolean') {
    return {
      ...filter,
      value: filter.value.toString(),
    };
  }

  return {
    ...filter,
    value: filter.value.toString(),
  };
};

const normalizeFilters = (filters: TFilter[]) => {
  return filters.map(normalizeFilter);
};

export default function useFilters() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const storeFilters = (
    filters: TFilter[],
    append = true,
    options: NavigateOptions = { scroll: false }
  ) => {
    const convertedFilters = normalizeFilters(filters);
    const newSearchParams = new URLSearchParams(append ? searchParams : undefined);

    convertedFilters.forEach(filter => {
      newSearchParams.set(filter.name, filter.value);
      if (filter.value === '') newSearchParams.delete(filter.name);
    });

    const paramsString = newSearchParams.toString();

    return router.push(`${pathname}${paramsString ? `?${paramsString}` : ''}`, options);
  };

  return { storeFilters, normalizeFilter, filters: searchParams };
}
