'use client';

import React, { createContext, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import io from 'socket.io-client';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import type { TUser } from 'business/user/types.d';
import { USER_REFRESH_TOKEN_NAME, USER_TOKEN_NAME } from 'constants/storage';
import useToggle from 'hooks/useToggle';
import { useRouter } from 'utils/navigation';
import type { TUserResponse } from 'business/user/repository/types.d';
interface IAuthorizationContext {
  isAuthorized: boolean;
  botUrl: string | null;
  isAuthorizationOpen: boolean;
  closeAuthorization(): void;
  openAuthorization(): void;
  user: TUserResponse['user'] | null;
  logout(): void;
}
export const AuthorizationContext = createContext<IAuthorizationContext>({
  isAuthorized: false,
  botUrl: null,
  isAuthorizationOpen: false,
  closeAuthorization: () => {},
  openAuthorization: () => {},
  user: null,
  logout: () => {}
});
const AuthorizationProvider: React.FC<HTMLAttributes<HTMLElement> & {
  isAuthorized: boolean;
  user: TUserResponse['user'] | null;
}> = ({
  isAuthorized: presetIsAuthorized,
  user: initialUser,
  children
}) => {
  const [botUrl, setBotUrl] = useState<null | string>(null);
  const [isOpen, open, close] = useToggle(false);
  const [user, setUser] = useState<TUserResponse['user'] | null>(initialUser);
  const [isAuthorized, setAuthorization] = useState<boolean>(presetIsAuthorized);
  const router = useRouter();
  const logout = () => {
    setUser(null);
    router.push('/');
  };
  useEffect(() => {
    const socket = io(process.env.NEXT_PUBLIC_AUTH_HOST as string);
    socket.connect();
    socket.on('loginInitiated', data => {
      setBotUrl(data?.url || null);
    });
    socket.on('loginSucceeded', (data: TUser) => {
      const options: OptionsType = {
        expires: dayjs().add(1, 'year').toDate()
      };
      setCookie(USER_TOKEN_NAME, data.token, options);
      setCookie(USER_REFRESH_TOKEN_NAME, data.refresh, options);
      setAuthorization(true);
      setUser(data.user);
      close();
      router.push('/my-account');
    });
    const id = {
      id: uuid()
    };
    socket.emit('initiateAuth', id);
    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    setAuthorization(presetIsAuthorized);
  }, [presetIsAuthorized]);
  const values = useMemo(() => ({
    botUrl,
    isAuthorized,
    isAuthorizationOpen: isOpen,
    closeAuthorization: close,
    openAuthorization: open,
    user,
    logout
  }), [botUrl, isAuthorized, isOpen, open, close, user]);
  return <AuthorizationContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AuthorizationProvider" data-sentry-source-file="AuthorizationContext.tsx">{children}</AuthorizationContext.Provider>;
};
export default AuthorizationProvider;