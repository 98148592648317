'use client';

import React, { useContext } from 'react';
import { useTranslations } from 'next-intl';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { AuthorizationContext } from 'contexts/AuthorizationContext';
import styles from './LoginPanel.module.scss';
const LoginPanel: React.FC = () => {
  const t = useTranslations('LoginPanel');
  const {
    openAuthorization,
    isAuthorized
  } = useContext(AuthorizationContext);
  if (isAuthorized) return null;
  return <div className={styles.root} data-sentry-component="LoginPanel" data-sentry-source-file="LoginPanel.tsx">
      <div className={styles.box}>
        <Typography variant="paragraph8" color="white" data-sentry-element="Typography" data-sentry-source-file="LoginPanel.tsx">
          {t('message')}
        </Typography>
        <Button variant="light" size="large" className={styles.button} onClick={openAuthorization} data-sentry-element="Button" data-sentry-source-file="LoginPanel.tsx">
          {t('login')}
        </Button>
      </div>
    </div>;
};
export default LoginPanel;