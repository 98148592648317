'use client';

import React from 'react';
import { ParentSize } from '@visx/responsive';
import ResizeObserver from 'resize-observer-polyfill';
import { Mercator } from '@visx/geo';
import { geoMercator } from 'd3-geo';
import ukraineGeo from 'public/assets/uk.geo.json';
import ukraineCities from 'public/assets/ua.cities.json';
import styles from './Ukraine.module.scss';
type TCityProperties = (typeof ukraineCities)['0'];
type TRegionProperties = (typeof ukraineGeo.features)['0']['properties'];
interface MapProps {
  withCities?: boolean;
  onCityHover?: (city: string, property: TCityProperties) => (event: React.TouchEvent<SVGPathElement> | React.MouseEvent<SVGPathElement>) => void;
  onCityClick?: (city: string) => void;
  onRegionHover?: (region: string, property: TRegionProperties) => (event: React.TouchEvent<SVGPathElement> | React.MouseEvent<SVGPathElement>) => void;
  onRegionClick?: (region: string) => void;
  onMouseLeave?: () => void;
  regionOpacityScale?: (region: string) => number;
  cityOpacityScale?: (city: string) => number;
}
interface FeatureShape {
  type: 'Feature';
  geometry: {
    coordinates: [number, number][][];
    type: 'Polygon';
  };
  properties: (typeof ukraineGeo.features)['0']['properties'];
}
const Ukraine: React.FC<MapProps> = props => <div className={styles.container} data-sentry-component="Ukraine" data-sentry-source-file="Ukraine.tsx">
    <ParentSize resizeObserverPolyfill={ResizeObserver} className={styles.resizer} data-sentry-element="ParentSize" data-sentry-source-file="Ukraine.tsx">
      {({
      width,
      height
    }) => <Map {...props} width={width} height={height} />}
    </ParentSize>
  </div>;
const Map: React.FC<MapProps & {
  width: number;
  height: number;
}> = ({
  onCityHover,
  onCityClick,
  onRegionHover,
  onRegionClick,
  onMouseLeave,
  cityOpacityScale,
  regionOpacityScale,
  width,
  height,
  withCities = false
}) => {
  if (width < 10) {
    return null;
  }
  const projection = geoMercator().fitExtent([[5, 5], [width, height]],
  // @ts-ignore
  ukraineGeo);
  return <svg width="100%" height="100%" style={{
    height: 'auto'
  }} data-sentry-element="svg" data-sentry-component="Map" data-sentry-source-file="Ukraine.tsx">
      <Mercator<FeatureShape>
    // @ts-ignore
    data={ukraineGeo.features} fitExtent={[[[5, 5], [width, height]],
    // @ts-ignore
    ukraineGeo]} data-sentry-element="Mercator" data-sentry-source-file="Ukraine.tsx">
        {({
        features
      }) => features.map(({
        feature,
        path
      }) => <path className={styles.region} key={`map-feature-${feature.properties['iso3166-2']}`} d={path as string} fill="var(--brand)" fillOpacity={regionOpacityScale?.(feature.properties['iso3166-2'])} onMouseOver={onRegionHover?.(feature.properties['iso3166-2'], feature.properties)} onTouchStart={onRegionHover?.(feature.properties['iso3166-2'], feature.properties)} onTouchMove={onRegionHover?.(feature.properties['iso3166-2'], feature.properties)} onClick={() => onRegionClick?.(feature.properties['iso3166-2'])} onMouseLeave={onMouseLeave} />)}
      </Mercator>
      {withCities && ukraineCities.map(city => {
      const [x, y] = projection([+city.lng, +city.lat]) || [];
      return <React.Fragment key={`city-${city.city}`}>
              <circle cx={x} cy={y} r={8} fill="var(--background)" />
              <circle className={styles.city} key={city.city} cx={x} cy={y} r={8} strokeWidth={2} fill="var(--brand)" fillOpacity={cityOpacityScale?.(city.city)} stroke="white" strokeOpacity={0.8} onMouseOver={onCityHover?.(city.city, city)} onTouchStart={onCityHover?.(city.city, city)} onTouchMove={onCityHover?.(city.city, city)} onClick={() => onCityClick?.(city.iso)} onMouseLeave={onMouseLeave} />
            </React.Fragment>;
    })}
    </svg>;
};
export default Ukraine;